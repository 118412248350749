import GATSBY_COMPILED_MDX from "/opt/build/repo/posts/can-chickens-eat-pineapple/index.mdx";
import React from "react";
import BlogLayout from "../components/bloglayout";
import {Link, graphql} from "gatsby";
import {MDXProvider} from "@mdx-js/react";
import SEO from "../components/seo";
import {Container, Row, Col, Badge} from "reactstrap";
import {slugify} from "../util/utilityFunctions";
import {GatsbyImage} from "gatsby-plugin-image";
import {getSrc} from "gatsby-plugin-image";
import BlogAvatar from "../components/BlogAvatar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook} from "@fortawesome/free-brands-svg-icons";
import {faTwitter} from "@fortawesome/free-brands-svg-icons";
import {faPinterest} from "@fortawesome/free-brands-svg-icons";
import {faWrench} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import Sidebar from "../components/sidebar";
import {ImageMDX} from "@components/mdx/ImageMDX";
import {TOC} from "@components/mdx/TOC";
const components = {
  ImageMDX: ({align, maxWidth, minWidth, caption, children}) => React.createElement(Figure, {
    style: {
      float: align,
      margin: align === "left" ? "0 20px 0 0" : align === "center" ? "auto" : "0 0 0 20px",
      maxWidth: maxWidth || "300px",
      minWidth: minWidth || "200px"
    }
  }, children, caption && React.createElement(Caption, null, caption)),
  ClearFix: () => React.createElement("div", {
    style: {
      clear: "both",
      display: "block"
    }
  }),
  TOC: ({children}) => React.createElement(TableOfContents, null, children),
  table: ({children}) => React.createElement(TableContainer, null, React.createElement(Table, null, children))
};
class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx;
    const siteTitle = this.props.data.site.siteMetadata.title;
    const siteUrl = this.props.data.site.siteMetadata.siteUrl;
    const getColor = tag => {
      if (tag === "Supplements") return "badge-supplements text-uppercase";
      if (tag === "Information") return "badge-info text-uppercase";
      if (tag === "Treatment") return "badge-treatment text-uppercase";
      if (tag === "Diet") return "badge-diet text-uppercase";
      if (tag === "Testimonial") return "badge-testimonial text-uppercase";
    };
    const FeaturedImage = post.frontmatter.featuredImage.childImageSharp.gatsbyImageData;
    const OgUrl = `${siteUrl}${post.fields.slug}`;
    const imagesrc = getSrc(post.frontmatter.featuredImage);
    const OgImage = `${siteUrl}${imagesrc}`;
    return React.createElement(BlogLayout, {
      location: this.props.location,
      title: siteTitle
    }, React.createElement(SEO, {
      title: post.frontmatter.title,
      description: post.frontmatter.description,
      image: OgImage,
      url: OgUrl,
      type: "blog"
    }), React.createElement(Container, null, React.createElement(Row, null, React.createElement(Col, {
      sm: "8",
      className: "content"
    }, React.createElement(PostWrapper, {
      itemScope: true,
      itemType: "https://schema.org/CreativeWork"
    }, React.createElement(TagBlock, null, React.createElement(PostTags, null, post.frontmatter.tags.map(tag => React.createElement(BadgeLi, {
      key: tag
    }, React.createElement(Link, {
      to: `/topic/${slugify(tag)}/`,
      className: "tag-badge",
      style: {
        fontSize: `50%`
      }
    }, tag))))), React.createElement(EntryHeader, null, React.createElement(EntryTitle, {
      itemProp: "headline"
    }, post.frontmatter.title), React.createElement(EntryMeta, null, React.createElement(BlogAvatar), React.createElement("span", {
      itemProp: "author",
      itemScope: true,
      itemType: "http://schema.org/Organization"
    }, React.createElement("span", {
      itemProp: "name"
    }, React.createElement("b", null, "Angelina Carter"))))), React.createElement(EntryContent, null, React.createElement(HeroImage, null, React.createElement(GatsbyImage, {
      image: FeaturedImage,
      alt: post.frontmatter.title
    })), React.createElement(MDXProvider, {
      components: components
    }, this.props.children), React.createElement(ShortLine), React.createElement("div", {
      className: "text-center"
    }, React.createElement("a", {
      href: "/resources/"
    }, React.createElement(DiscussButton, null, React.createElement(FontAwesomeIcon, {
      icon: faWrench
    }), " See My Chicken Tools")))), React.createElement(EntryShare, null, React.createElement("div", {
      className: "socialicon"
    }, React.createElement("ul", {
      className: "list-inline m-0"
    }, React.createElement("li", {
      className: "list-inline-item font-weight-bold"
    }, " Share this article:"), React.createElement("li", {
      className: "list-inline-item",
      style: {
        verticalAlign: `middle`
      }
    }, React.createElement("a", {
      href: "https://facebook.com/sharer.php?u=" + OgUrl,
      rel: "noopener noreferrer",
      target: "_blank",
      "aria-label": "Facebook"
    }, React.createElement(FontAwesomeIcon, {
      size: "2x",
      style: {
        marginRight: 10
      },
      icon: faFacebook
    }))), React.createElement("li", {
      className: "list-inline-item",
      style: {
        verticalAlign: `middle`
      }
    }, React.createElement("a", {
      href: "http://pinterest.com/pin/create/button/?url=" + OgUrl + "&media=" + OgImage + "&description=" + post.frontmatter.title,
      rel: "noopener noreferrer",
      target: "_blank",
      "aria-label": "Pinterest"
    }, React.createElement(FontAwesomeIcon, {
      size: "2x",
      style: {
        marginRight: 10
      },
      icon: faPinterest
    }))), React.createElement("li", {
      className: "list-inline-item",
      style: {
        verticalAlign: `middle`
      }
    }, React.createElement("a", {
      href: "https://twitter.com/intent/tweet?text=" + post.frontmatter.title + " @chickenyard" + "&url=" + OgUrl,
      rel: "noopener noreferrer",
      target: "_blank",
      "aria-label": "Twitter"
    }, React.createElement(FontAwesomeIcon, {
      size: "2x",
      icon: faTwitter
    })))))))), React.createElement(Col, {
      sm: "4"
    }, React.createElement(Sidebar)))));
  }
}
BlogPostTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPostTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
const PostWrapper = (styled.div)`
  max-width: 720px;
  margin: auto;
`;
const ShortLine = (styled.hr)`
  width: 75%;
`;
const HeroImage = (styled.div)`
  max-height: 600px;
  margin-bottom: 10px;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 6px;
  img {
    border-radius: 6px;
  }
`;
const EntryTitle = (styled.h1)`
  -webkit-text-fill-color: transparent;
  background: linear-gradient(#867350,#544b38);
  -webkit-background-clip: text;
  color: #fff;
  font-size: 3.2rem;
  font-weight: bold;
  line-height: 1.25;
  @media only screen and (max-width: 768px) {
    font-size: 2.2rem;
  }
`;
const EntryHeader = (styled.header)`
margin-bottom: 20px;
`;
const EntryMeta = (styled.div)`
  font-family: "Open Sans";
  font-size: 0.8em;
  color: #fff;
  margin-bottom: 5px;
  text-transform: uppercase;
`;
const EntryContent = (styled.div)`
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background: #fff;
  padding: 30px;
  box-shadow: 0 8px 40px 0 rgba(7, 7, 7, 0.2), 0 12px 40px 0 rgba(7, 7, 7, 0.2);
  .gatsby-resp-image-image {
    border-radius: 6px;
  }
  @media (max-width: 520px) {
    padding: 15px;
  }
`;
const EntryShare = (styled.div)`
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-shadow: 0 0px 40px 0 rgba(7, 7, 7, 0.2), 0 0px 40px 0 rgba(7, 7, 7, 0.2);
  background: #3e372f;
  color: #fff;
  padding: 10px;
  text-align: center;
`;
const TagBlock = (styled.div)``;
const PostTags = (styled.ul)`
  padding: 0;
  display: inline-flex;
  list-style: none;
  margin-bottom: .5rem;
`;
const BadgeLi = (styled.li)`
  a {
    color: white !important;
  }
  &:nth-child(1) {
    margin-right: 5px;
  }
`;
const DiscussButton = (styled.button)`
  background: #e39f1e;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
  border: none !important;
  color: #fff;
  display: inline-block;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 4px;
  transition: 0.5s;
  margin: auto;
  &:hover {
    box-shadow: none;
  }
`;
const BreadCrumbs = (styled.small)`
  color: #fff;
`;
const Figure = (styled.figure)`
  max-width: 300px;
  min-width: 200px;
  @media only screen and (max-width: 600px) {
    max-width: 200px;
    min-width: 150px;
  }
`;
const Caption = (styled.figcaption)`
  color: #7c6b4b !important;
  font-family: Jost;
  text-align: center;
  margin-bottom: 1rem;
`;
const TableOfContents = (styled.div)`
  background-color: #3f362b;
  //border: 1px dashed #000;
  border-radius: 6px;
  color: #fff;
  padding: 20px;
  margin-bottom: 20px;

  p {
    font-family: "Jost" !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
    font-weight: 600 !important;
    font-size: 1rem !important;
  }
  & a:hover {
    //color: #fff !important;
  }
  & ul {
    padding: 0;
  }
  & ul li {
    font-size: 1.2rem !important;
    list-style: none;
    line-height: 1.6;
    margin: 0;
    a {
      color: #e39f1e !important;
      border-bottom: 0 !important;
      &:hover {
        //color: var(--tochover) !important;
        background: transparent !important;
        text-decoration: underline !important;
      }
    }
  }
  & ol {
    padding: 0;
  }
  & ol li {
    font-size: 1.2rem !important;
    list-style: none;
    line-height: 1.6;
    margin: 0;
    a {
      color: #e39f1e !important;
      border-bottom: 0 !important;
      &:hover {
        //color: var(--tochover) !important;
        background: transparent !important;
        text-decoration: underline !important;
      }
    }
  }
  & ol li,
  ul li {
    & ol,
    ul {
      margin-left: 30px;
    }
  }
`;
const TableContainer = (styled.div)`
  display: block;
  width: 100%;
  overflow-x: auto;
`;
const Table = (styled.table)`
  border: 3px solid #49391d;
  width: 100%;
  margin-bottom: 1rem;
  thead {
    background-color: #8e7e6a;
    color: #fff;
    border: 3px solid #49391d;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  th,
  td {
    vertical-align: middle;
    text-align: center;
    border: 3px solid #49391d;
    padding: 0.75rem;
  }
  tbody {
    tr:nth-of-type(2n + 1) {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
`;
export const pageQuery = graphql`query ($slug: String!) {
  site {
    siteMetadata {
      title
      author
      siteUrl
    }
  }
  mdx(fields: {slug: {eq: $slug}}) {
    id
    body
    fields {
      slug
    }
    frontmatter {
      title
      tags
      categories
      description
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        childImageSharp {
          gatsbyImageData(width: 720, quality: 80, layout: CONSTRAINED)
        }
      }
    }
  }
}
`;
